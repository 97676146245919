import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const BACKEND_URL = environment.apiUrl;

export interface Teams {
  _id: string;
  name: string;
  teams: any;
  company: any;
}

export interface Agents {
  _id: string;
  name: string;
  phone: number;
  email: string;
  token: string;
  resetToken: string,
  resetTokenDate: Date
}

export interface Category {
  _id: string;
  links: any;
  teams: any;
  name: string;
}

export interface Links {
  _id: string;
  title: any;
}

export interface Integration {
  tokenPipedrive: string;
  statusPipedrive: boolean;
}

export interface User {
  name: string;
  phone: string;
  email: string;
  password: string;
}

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) {
  }

  getUserSettings(companyId: any): Observable<User> {
    return this.http.get<User>(BACKEND_URL + '/user/settings/' + companyId);
  }

  getTeams(companyId: any): Observable<Teams[]> {
    return this.http.get<Teams[]>(BACKEND_URL + '/team/' + companyId);
  }

  createTeam(companyId: any, data: any): Observable<Teams[]> {
    return this.http.post<Teams[]>(BACKEND_URL + '/team/' + companyId, data);
  }

  deleteTeam(companyId: any, teamId: any): Observable<Teams[]> {
    return this.http.delete<Teams[]>(BACKEND_URL + '/team/' + companyId + '/' + teamId);
  }

  updateTeams(companyId: any, data: any): Observable<Teams[]> {
    return this.http.put<Teams[]>(BACKEND_URL + '/team/' + companyId, data);
  }

  getAgents(companyId: any): Observable<Agents[]> {
    return this.http.get<Agents[]>(BACKEND_URL + '/agents/' + companyId);
  }

  createAgent(data: any): Observable<Agents[]> {
    return this.http.post<Agents[]>(BACKEND_URL + '/agents/' + data.companyId, data);
  }

  updateAgent(companyId: any, data: any): Observable<Agents[]> {
    return this.http.put<Agents[]>(BACKEND_URL + '/agents/' + companyId, data);
  }

  deleteAgent(companyId: any, agentId: any): Observable<Agents[]> {
    return this.http.delete<Agents[]>(BACKEND_URL + '/agents/' + companyId + '/' + agentId);
  }

  getCategory(companyId: any): Observable<Category[]> {
    return this.http.get<Category[]>(BACKEND_URL + '/categories/' + companyId);
  }

  createCategory(companyId: any, data: any): Observable<Category[]> {
    return this.http.post<Category[]>(BACKEND_URL + '/categories/' + companyId, data);
  }

  updateCategory(companyId: any, data: any): Observable<Category[]> {
    return this.http.put<Category[]>(BACKEND_URL + '/categories/' + companyId, data);
  }

  deleteCategory(companyId: any, agentId: any): Observable<Category[]> {
    return this.http.delete<Category[]>(BACKEND_URL + '/categories/' + companyId + '/' + agentId);
  }

  getLinks(companyId: any): Observable<Links[]> {
    return this.http.get<Links[]>(BACKEND_URL + '/links/' + companyId);
  }

  createLinks(companyId: any,data: any): Observable<Links[]> {
    return this.http.post<Links[]>(BACKEND_URL + '/links/' + companyId, data);
  }

  updateLink(companyId: any, data: any): Observable<Links[]> {
    return this.http.put<Links[]>(BACKEND_URL + '/links/' + companyId, data);
  }

  deleteLink(companyId: any, agentId: any): Observable<Links[]> {
    return this.http.delete<Links[]>(BACKEND_URL + '/links/' + companyId + '/' + agentId);
  }

  updatePipedriveIntegration(companyId: any, data: any): Observable<Integration[]> {
    return this.http.post<Integration[]>(BACKEND_URL + '/integration/pipedrive/' + companyId, data);
  }

  getIntegrations(companyId: any): Observable<any> {
    return this.http.get<any>(BACKEND_URL + '/integration/pipedrive/' + companyId);
  }

}
