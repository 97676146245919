import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../_services/admin.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [ConfirmationService]
})
export class CategoryComponent implements OnInit {

  tableData: any;
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  createCategoryForm: FormGroup;
  updateCategoryForm: FormGroup;
  formSubmitted = false;
  teams: SelectItem[] = [];
  companyId = this.authService.getCompany();

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'links', header: 'Links' },
    ];
    this.getCategory();
    this.getTeams();
    this.createCategoryForm = this.formBuilder.group({
      teams: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
    this.updateCategoryForm = this.formBuilder.group({
      _id: [''],
      teams: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  getCategory() {
    this.isLoading = true;
    this.adminService.getCategory(this.companyId).subscribe(data => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  getTeams() {
    this.adminService.getTeams(this.companyId).subscribe(data => {
      this.teams = data.map((line) => {
        return {label: line.name, value: line._id};
      });
    });
  }

  addCategory() {
    this.addModal = true;
  }

  editRow(data) {
    const teamsIds = [];
    data.teams.map((team) => {
      teamsIds.push(team._id);
    });
    const value = {
      _id: data._id,
      name: data.name,
      teams: teamsIds
    }
    this.updateCategoryForm.patchValue(value);
    this.editModal = true;
  }

  createCategory() {
    this.formSubmitted = true;
    const data = {
      name: this.createCategoryForm.value.name,
      teams: this.createCategoryForm.value.teams,
      company: this.companyId
    }
    if(this.createCategoryForm.valid) {
      this.isLoading = true;
      this.adminService.createCategory(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.getCategory();
        this.addModal = false;
        this.createCategoryForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateCategory() {
    const data = {
      _id: this.updateCategoryForm.value._id,
      name: this.updateCategoryForm.value.name,
      teams: this.updateCategoryForm.value.teams
    }
    if(this.updateCategoryForm.valid) {
      this.isLoading = true;
      this.adminService.updateCategory(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.getCategory();
        this.isLoading = false;
        this.editModal = false;
        this.updateCategoryForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  deleteRow(categoryId) {
    if(this.tableData.length > 1) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the category?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.adminService.deleteCategory(this.companyId, categoryId).subscribe(() => {
            this.getCategory();
          });
        }
      });
    } else {
      this.notificationService.showError('To delete a category, please add a new category first.');
    }
  }

  exportExcel() {
    let data = [];
    data = this.tableData.map(value => [
      value.name,
      value.links.length
    ]);
    data.splice(0, 0, this.cols.map(column => column.header));
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Category');

    /* save to file */
    const time = Date.now();
    const fileName = 'category-' + time + '.xlsx';
    XLSX.writeFile(wb, fileName);
  }

}
