import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { PhoneValidator } from '../_util/phone.validator';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
  providers: [ConfirmationService]
})
export class TeamsComponent implements OnInit {

  tableData: any;
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  addAgentModal = false;
  createTeamForm: FormGroup;
  updateTeamForm: FormGroup;
  addAgentForm: FormGroup;
  formSubmitted = false;
  agents: SelectItem[] = [];
  companyId = this.authService.getCompany();
  addNewAgent: boolean = false;

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'agents', header: 'Agents' }
    ];
    this.getTeams();
    this.getAgents();
    this.createTeamForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      agents: ['', [Validators.required]],
      company: ['']
    });
    this.updateTeamForm = this.formBuilder.group({
      _id: [''],
      name: ['', [Validators.required]],
      agents: ['', [Validators.required]],
      company: ['']
    });
    this.addAgentForm = this.formBuilder.group({
      agentName: ['', [Validators.required]],
      agentPhone: ['', [Validators.required, PhoneValidator.checkLimit(10000000000,9999999999999999)]],
      agentEmail: ['', [Validators.required]]
    });
  }

  getTeams() {
    this.isLoading = true;
    this.adminService.getTeams(this.companyId).subscribe(data => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  getAgents() {
    this.adminService.getAgents(this.companyId).subscribe(data => {
      this.agents = data.map((line) => {
        return {label: line.name, value: line._id};
      });
    });
  }

  addTeam() {
    this.addModal = true;
  }

  editRow(data) {
    const agentIds = [];
    data.agents.map((agent) => {
      agentIds.push(agent._id);
    });
    const value = {
      _id: data._id,
      name: data.name,
      agents: agentIds
    }
    this.updateTeamForm.patchValue(value);
    this.editModal = true;
  }

  createTeam() {
    this.formSubmitted = true;
    const data = {
      name: this.createTeamForm.value.name,
      agents: this.createTeamForm.value.agents,
      company: this.companyId
    }
    if(this.createTeamForm.valid) {
      this.isLoading = true;
      this.adminService.createTeam(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.addModal = false;
        this.getTeams();
        this.createTeamForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateTeam() {
    const data = {
      _id: this.updateTeamForm.value._id,
      name: this.updateTeamForm.value.name,
      agents: this.updateTeamForm.value.agents
    }
    if(this.updateTeamForm.valid) {
      this.isLoading = true;
      this.adminService.updateTeams(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.editModal = false;
        this.getTeams();
        this.updateTeamForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  deleteRow(data) {
    if(this.tableData.length > 1) {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the team?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.adminService.deleteTeam(this.companyId, data).subscribe(() => {
            this.getTeams();
          });
        }
      });
    } else {
      this.notificationService.showError('To delete a team, please add a new team first.');
    }
  }

  openAddAgent() {
    this.addAgentModal = true;
  }

  addAgent() {
    this.formSubmitted = true;
    const data = {
      email: this.addAgentForm.value.agentEmail,
      phone: this.addAgentForm.value.agentPhone,
      name: this.addAgentForm.value.agentName,
      companyId: this.companyId
    };
    if(this.addAgentForm.valid) {
      this.isLoading = true;
      this.adminService.createAgent(data).subscribe(() => {
        this.getAgents();
        this.formSubmitted = false;
        this.isLoading = false;
        this.addAgentModal = false;
        this.addAgentForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  exportExcel() {
    console.log(this.tableData);
    let data = [];
    this.tableData.map(value => {
     let row = [];
     row.push(value.name);
     value.agents.forEach(element => {
       row.push(element.name);
     });
     data.push(row);
    });
    data.splice(0, 0, this.cols.map(column => column.header));
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Teams');

    /* save to file */
    const time = Date.now();
    const fileName = 'teams-' + time + '.xlsx';
    XLSX.writeFile(wb, fileName);
  }

}
