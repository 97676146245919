import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  forgotPasswordForm: FormGroup;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private notificationService: NotificationService) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }

  forgotPassword() {
    this.formSubmitted = true;
    if(this.forgotPasswordForm.valid) {
      this.isLoading = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(res => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.notificationService.showSuccess('Password reset instruction send your e-mail address!');
        this.router.navigate(["/login"]);
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.notificationService.showError('Something went wrong!');
      });
    } else {
      this.notificationService.showError('Please fill field!');
    }
  }

  backSignIn() {
    this.router.navigate(["/login"]);
  }

}
