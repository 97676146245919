import { Component, OnInit } from "@angular/core";
import { AdminService } from "../_services/admin.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../_services/auth.service";
import { PhoneValidator } from "../_util/phone.validator";
import { NotificationService } from "../_services/notification.service";
import * as XLSX from "xlsx";
import { ConfirmationService, SelectItem } from "primeng/api";

@Component({
  selector: "app-agents",
  templateUrl: "./agents.component.html",
  styleUrls: ["./agents.component.scss"],
  providers: [ConfirmationService],
})
export class AgentsComponent implements OnInit {
  tableData: any;
  cols: any[];
  isLoading = true;
  addModal = false;
  editModal = false;
  createAgentForm: FormGroup;
  updateAgentForm: FormGroup;
  formSubmitted = false;
  companyId = this.authService.getCompany();
  preDays: any = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  days: SelectItem[] = [
    { label: "Monday", value: { name: "monday" } },
    { label: "Tuesday", value: { name: "tuesday" } },
    { label: "Wednesday", value: { name: "wednesday" } },
    { label: "Thursday", value: { name: "thursday" } },
    { label: "Friday", value: { name: "friday" } },
    { label: "Saturday", value: { name: "saturday" } },
    { label: "Sunday", value: { name: "sunday" } },
  ];

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.cols = [
      { field: "name", header: "Name" },
      { field: "email", header: "E-mail" },
      { field: "phone", header: "Phone" },
      { field: "days", header: "Days" },
    ];
    this.getAgents();
    this.createAgentForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          PhoneValidator.checkLimit(10000000000, 9999999999999999),
        ],
      ],
      name: ["", [Validators.required]],
      days: [""],
      pipedriveUserId: [""],
    });
    this.updateAgentForm = this.formBuilder.group({
      _id: [""],
      email: ["", [Validators.required]],
      phone: [
        "",
        [
          Validators.required,
          PhoneValidator.checkLimit(10000000000, 9999999999999999),
        ],
      ],
      name: ["", [Validators.required]],
      days: [""],
      pipedriveUserId: [""],
    });
  }

  getAgents() {
    this.isLoading = true;
    this.adminService.getAgents(this.companyId).subscribe((data) => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  addAgent() {
    this.addModal = true;
  }

  editRow(data) {
    const days = [];
    if(data.days.length > 0) {
      data.days.map((day) => {
        if(day['monday']) {
          days.push({name: 'monday'});
        }
        if (day['tuesday']) {
          days.push({name: 'tuesday'});
        }
        if (day['wednesday']) {
          days.push({name: 'wednesday'});
        }
        if (day['thursday']) {
          days.push({name: 'thursday'});
        } 
        if (day['friday']) {
          days.push({name: 'friday'});
        } 
        if (day['saturday']) {
          days.push({name: 'saturday'});
        } 
        if (day['sunday']) {
          days.push({name: 'sunday'});
        }
      });
    }
    const value = {
      _id: data._id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      pipedriveUserId: data.pipedriveUserId,
      days: days,
    };
    this.updateAgentForm.patchValue(value);
    // console.log(this.updateAgentForm);
    this.editModal = true;
  }

  createAgent() {
    this.formSubmitted = true;
    const formDays = { ...this.preDays };
    this.createAgentForm.value.days.map((day) => {
      if (day.name === "monday") {
        formDays["monday"] = true;
      } else if (day.name === "tuesday") {
        formDays["tuesday"] = true;
      } else if (day.name === "wednesday") {
        formDays["wednesday"] = true;
      } else if (day.name === "thursday") {
        formDays["thursday"] = true;
      } else if (day.name === "friday") {
        formDays["friday"] = true;
      } else if (day.name === "saturday") {
        formDays["saturday"] = true;
      } else if (day.name === "sunday") {
        formDays["sunday"] = true;
      }
    });
    const data = {
      email: this.createAgentForm.value.email,
      phone: this.createAgentForm.value.phone,
      password: this.createAgentForm.value.password,
      name: this.createAgentForm.value.name,
      companyId: this.companyId,
      pipedriveUserId: this.createAgentForm.value.pipedriveUserId,
      days: formDays,
    };
    if (this.createAgentForm.valid) {
      this.isLoading = true;
      this.adminService.createAgent(data).subscribe(
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
          this.addModal = false;
          this.getAgents();
          this.createAgentForm.reset();
        },
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
        }
      );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError("Please fill fields!");
    }
  }

  updateAgent() {
    this.formSubmitted = true;
    const formDays = { ...this.preDays };
    this.updateAgentForm.value.days.map((day) => {
      if (day.name === "monday") {
        formDays["monday"] = true;
      } else if (day.name === "tuesday") {
        formDays["tuesday"] = true;
      } else if (day.name === "wednesday") {
        formDays["wednesday"] = true;
      } else if (day.name === "thursday") {
        formDays["thursday"] = true;
      } else if (day.name === "friday") {
        formDays["friday"] = true;
      } else if (day.name === "saturday") {
        formDays["saturday"] = true;
      } else if (day.name === "sunday") {
        formDays["sunday"] = true;
      }
    });
    const data = {
      _id: this.updateAgentForm.value._id,
      email: this.updateAgentForm.value.email,
      phone: this.updateAgentForm.value.phone,
      password: this.updateAgentForm.value.password,
      name: this.updateAgentForm.value.name,
      company: this.companyId,
      pipedriveUserId: this.updateAgentForm.value.pipedriveUserId,
      days: formDays
    };
    if (this.updateAgentForm.valid) {
      this.isLoading = true;
      this.adminService.updateAgent(this.companyId, data).subscribe(
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
          this.editModal = false;
          this.getAgents();
          this.updateAgentForm.reset();
        },
        () => {
          this.formSubmitted = false;
          this.isLoading = false;
        }
      );
    } else {
      this.formSubmitted = true;
      this.notificationService.showError("Please fill fields!");
    }
  }

  deleteRow(agentId) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete the agent?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.adminService.deleteAgent(this.companyId, agentId).subscribe(() => {
          this.getAgents();
        });
      },
    });
  }

  exportExcel() {
    let data = [];
    data = this.tableData.map((value) => [
      value.name,
      value.email,
      value.phone,
    ]);
    data.splice(
      0,
      0,
      this.cols.map((column) => column.header)
    );
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Agents");

    /* save to file */
    const time = Date.now();
    const fileName = "agents-" + time + ".xlsx";
    XLSX.writeFile(wb, fileName);
  }
}
