import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';
import { PhoneValidator } from 'src/app/_util/phone.validator';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  signUpForm: FormGroup;
  countryList: SelectItem[];
  selectedCountry: string = "90";
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private notificationService: NotificationService) { }

  ngOnInit() {
    this.isLoading = false;
    this.signUpForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email]],
      phone: ['', [Validators.required, PhoneValidator.checkLimit(10000000000,9999999999999999)]],
      password: ['', [Validators.required]],
      rePassword: ['', [Validators.required]],
      company: ['', [Validators.required]]
    }, {validator: this.checkPasswords, });

    this.getCountries();
  }

  signup() {
    this.formSubmitted = true;
    if(this.signUpForm.valid) {
      this.authService.createAgent(this.signUpForm.value).subscribe(res => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.notificationService.showSuccess('Account created! Please login with information.');
        this.router.navigateByUrl('/login');
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.notificationService.showError('Please fill fields!');
    }
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('password').value;
    let rePassword = group.get('rePassword').value;
    return pass === rePassword ? null : { notSame: true }     
  }

  getCountries() {
    this.authService.getCountries().subscribe(res => {
      this.countryList = res.map((country) => {
        return {label: '+' + country.callingCodes[0], value: country.callingCodes[0], flag: country.flag};
      });
    })
  }

}
