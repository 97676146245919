import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AdminService } from '../_services/admin.service';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'app-pipedrive',
  templateUrl: './pipedrive.component.html',
  styleUrls: ['./pipedrive.component.scss']
})
export class PipedriveComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  integrationForm: FormGroup;
  companyId = this.authService.getCompany();

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.isLoading = false;
    this.integrationForm = this.formBuilder.group({
      tokenPipedrive: [''],
      pipedriveAdFieldsApiKey: [''],
      pipedriveAdIdFieldsApiKey: [''],
      pipedriveAdSetFieldsApiKey: [''],
      pipedriveCampaignFieldsApiKey: [''],
      pipedriveMediumFieldsApiKey: [''],
      pipedriveSourceFieldsApiKey: ['']
    });
    this.getInitialValues();
  }

  integrationFormAction() {
    if(this.integrationForm.valid) {
      const data = {
        tokenPipedrive: this.integrationForm.value.tokenPipedrive,
        statusPipedrive: true
      }
      this.adminService.updatePipedriveIntegration(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    }
  }

  getInitialValues() {
    this.adminService.getIntegrations(this.companyId).subscribe((data) => {
      const value = {
        tokenPipedrive: data.integration.tokenPipedrive,
        pipedriveAdFieldsApiKey: data.integration.pipedriveAdFieldsApiKey,
        pipedriveAdIdFieldsApiKey: data.integration.pipedriveAdIdFieldsApiKey,
        pipedriveAdSetFieldsApiKey: data.integration.pipedriveAdSetFieldsApiKey,
        pipedriveCampaignFieldsApiKey: data.integration.pipedriveCampaignFieldsApiKey,
        pipedriveMediumFieldsApiKey: data.integration.pipedriveMediumFieldsApiKey,
        pipedriveSourceFieldsApiKey: data.integration.pipedriveSourceFieldsApiKey
      }
      this.integrationForm.patchValue(value);
      this.formSubmitted = false;
      this.isLoading = false;
    }, () => {
      this.formSubmitted = false;
      this.isLoading = false;
    });
  }

}
