import { Component, OnInit } from '@angular/core';
import { AdminService } from '../_services/admin.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { SelectItem } from 'primeng/api';
import { NotificationService } from '../_services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  isLoading = true;
  step: number = 0;
  wizardForm: FormGroup;
  teams: SelectItem[] = [];
  categories: SelectItem[] = [];
  companyId = this.authService.getCompany();
  formSubmitted = false;
  createNewCategory = false;
  createNewTeam = false;
  agentSaved = false;
  agents = [];
  category;
  team;
  link;

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService, private router: Router) { }

  ngOnInit() {
    this.isLoading = false;
    this.wizardForm = this.formBuilder.group({
      agents: this.formBuilder.array([
        this.addAgentFormGroup()
      ]),
      teamName: ['', [Validators.required]],
      categoryName: [''],
      category: ['', [Validators.required]],
      title: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
    this.getTeams();
    this.getCategories();
  }

  addMoreAgent() {
    (<FormArray>this.wizardForm.get('agents')).push(this.addAgentFormGroup());
  }

  deleteMoreAgent(i) {
    (<FormArray>this.wizardForm.get('agents')).removeAt(i);
  }

  addAgentFormGroup(): FormGroup {
    return this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });
  }

  async wizardFormSubmit() {
    this.formSubmitted = true;
    if (this.wizardForm.valid) {
      this.team = await this.updateTeam();
      this.category = await this.createCategory();
      this.link = await this.createLink();
      this.router.navigateByUrl('/app/links');
    } else {
      this.notificationService.showError('Form not valid! Please fill required field!');
    }
  }

  async updateTeam() {
    const agentIds = [];
    this.agents.map(agent => {
      agentIds.push(agent.response._id);
    });
    const data = {
      _id: this.teams[0].value,
      agents: agentIds,
      name: this.wizardForm.value.teamName
    }
    this.isLoading = true;
    return await this.adminService.updateTeams(this.companyId, data).toPromise();
    
  }

  async createAgent(agent) {
    const data = {
      email: agent.email,
      phone: agent.phone,
      name: agent.name,
      companyId: this.companyId
    };
    await this.adminService.createAgent(data).subscribe(res => {
      this.agentSaved = true;
      this.agents.push(res);
      this.notificationService.showSuccess('Agent ' + data.name + ' registered!');
      return res;
    }, (error => {
      this.notificationService.showError(error);
    }));
  }

  async createCategory() {
    if (this.createNewCategory) {
      this.formSubmitted = true;
      const data = {
        name: this.wizardForm.value.categoryName,
        teams: this.teams[0].value,
        company: this.companyId
      }
      this.isLoading = true;
      return await this.adminService.createCategory(this.companyId, data).toPromise();
    } else {
      const data = {
        _id: this.wizardForm.value.category,
        teams: this.teams[0].value
      }
      this.isLoading = true;
      return await this.adminService.updateCategory(this.companyId, data).toPromise();
    }
  }

  async createLink() {
    this.formSubmitted = true;
    const data = {
      teams: this.teams[0].value,
      category: this.wizardForm.value.category,
      title: this.wizardForm.value.title,
      message: this.wizardForm.value.message,
      company: this.companyId
    };
    this.isLoading = true;
    this.notificationService.showSuccess('Wizard completed!');
    return await this.adminService.createLinks(this.companyId, data).toPromise();
  }

  goToStep(step: number) {
    this.step = step;
  }

  getTeams() {
    this.adminService.getTeams(this.companyId).subscribe(data => {
      this.teams = data.map((line) => {
        return { label: line.name, value: line._id };
      });
    });
  }

  getCategories() {
    this.adminService.getCategory(this.companyId).subscribe(data => {
      this.categories = data.map((line) => {
        return { label: line.name, value: line._id };
      });
      const newCategory = { label: "Add new category", value: "newCategory" };
      this.categories.push(newCategory);
    });
  }

  onChangeNewCategory(event) {
    if (event.value === 'newCategory') {
      this.createNewCategory = true;
    } else {
      this.createNewCategory = false;
      this.category = event.value;
    }
  }

  onClickNewCategory() {
    if (this.createNewCategory) {
      const data = {
        name: this.wizardForm.value.categoryName,
        teams: this.teams[0].value,
        company: this.companyId
      }
      this.adminService.createCategory(this.companyId, data).subscribe(res => {
        this.category = res['response']._id;
        this.notificationService.showSuccess('Category created!');
        this.getCategories();
        this.wizardForm.patchValue({
          category: this.category
        })
        this.createNewCategory = false;
      });
    }
  }

  async saveAgent() {
    if (this.wizardForm.controls.agents.valid) {
      this.wizardForm.value.agents.map(async agent => {
        return await this.createAgent(agent);
      });
    } else {
      this.notificationService.showError('Agent information not valid!');
    }
  }

  nextStep() {
    if(this.createNewCategory) {
      this.notificationService.showError('Please save category!');
    } else if(!this.agentSaved) {
      if(this.step === 2) {
        this.notificationService.showError('Please save agent!');
      } else {
        this.step++;
      }
    } else {
      this.step++;
    }
  }

  beforeStep() {
    if (this.step !== 0) { this.step--; }
  }

}
