import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../_services/admin.service';
import { AuthService } from '../_services/auth.service';
import { NotificationService } from '../_services/notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  settingsForm: FormGroup;
  companyId = this.authService.getCompany();

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private authService: AuthService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.isLoading = false;
    this.settingsForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      rePassword: [''],
    });
    this.getUserSettings();
  }

  getUserSettings() {
    this.adminService.getUserSettings(this.companyId).subscribe((data) => {
      const value = {
        name: data.name,
        phone: data.phone,
        email: data.email,
      }
      this.settingsForm.patchValue(value);
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  settingsFormAction() {
    //TODO
  }

}
