import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/_services/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  isLoading = true;
  formSubmitted = false;
  resetPasswordForm: FormGroup;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private notificationService: NotificationService) { }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      repassword: ['', [Validators.required]]
    });
  }

  resetPassword() {
    this.formSubmitted = true;
    this.isLoading = true;
    const data = {
      'password': this.resetPasswordForm.value.password
    }
    this.authService.resetPassword(data, this.route.snapshot.params.token).subscribe(res => {
      this.formSubmitted = false;
      this.isLoading = false;
      this.notificationService.showSuccess('Password changed!');
      this.router.navigate(["/login"]);
    }, () => {
      this.formSubmitted = false;
      this.isLoading = false;
    });
  }

  backSignIn() {
    this.router.navigate(["/login"]);
  }

}
