import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './main.component';

import {LinksComponent} from '../links/links.component'
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AgentsComponent } from '../agents/agents.component';
import { TeamsComponent } from '../teams/teams.component';
import { CategoryComponent } from '../category/category.component';
import { AuthGuard } from '../_guards/auth-guard';
import { WizardComponent } from '../wizard/wizard.component';
import { SettingsComponent } from '../settings/settings.component';
import { PipedriveComponent } from '../pipedrive/pipedrive.component';

export const RoutingComponents = [
    DashboardComponent,
    AgentsComponent,
    TeamsComponent,
    LinksComponent,
    CategoryComponent,
    WizardComponent,
    SettingsComponent,
    PipedriveComponent
];

const routes: Routes = [
    {
        path: 'app', component: MainComponent,
        canActivate: [AuthGuard],
        children: [
            {path: 'dashboard', component: DashboardComponent},
            {path: 'agents', component: AgentsComponent},
            {path: 'teams', component: TeamsComponent},
            {path: 'links', component: LinksComponent},
            {path: 'category', component: CategoryComponent},
            {path: 'wizard', component: WizardComponent},
            {path: 'settings', component: SettingsComponent},
            {path: 'pipedrive', component: PipedriveComponent},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})

export class MainRoutingModule {
}