import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectItem, Message, ConfirmationService } from 'primeng/api';
import * as XLSX from 'xlsx';

import { AdminService } from '../_services/admin.service';
import { AuthService } from '../_services/auth.service';
import { environment } from "../../environments/environment";
import { NotificationService } from '../_services/notification.service';

const BACKEND_URL = environment.apiUrl;

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  providers: [ConfirmationService]
})
export class LinksComponent implements OnInit {

  tableData: any;
  isLoading = true;
  addModal = false;
  editModal = false;
  createLinkForm: FormGroup;
  updateLinkForm: FormGroup;
  formSubmitted = false;
  teams: SelectItem[] = [];
  pipes: SelectItem[] = [];
  categories: SelectItem[] = [];
  companyId = this.authService.getCompany();
  cols: any[];
  msgs: Message[] = [];
  referenceCode: boolean = true;
  leadForm: boolean = false;
  languages: SelectItem[] = [
    { label: 'English', value: 'EN' },
    { label: 'Türkçe', value: 'TR' }
  ]

  constructor(private adminService: AdminService, 
    private formBuilder: FormBuilder, 
    private authService: AuthService, 
    private notificationService: NotificationService, 
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.getLinks();
    this.getTeams();
    this.getCategories();
    this.getPipes();
    this.createLinkForm = this.formBuilder.group({
      teams: ['', [Validators.required]],
      title: ['', [Validators.required]],
      category: ['', [Validators.required]],
      message: ['', [Validators.required]],
      reference: this.referenceCode,
      leadForm: this.leadForm,
      languageCode: ['EN'],
      pipeId: [1]
    });
    this.updateLinkForm = this.formBuilder.group({
      _id: [''],
      teams: ['', [Validators.required]],
      title: ['', [Validators.required]],
      category: ['', [Validators.required]],
      message: ['', [Validators.required]],
      reference: [''],
      leadForm: [''],
      languageCode: [''],
      click: [''],
      pipeId: [1]
    });
    this.cols = [
      { field: 'title', header: 'Title' },
      { field: 'category', header: 'Category' },
      { field: 'click', header: 'Click' },
      { field: 'reference', header: 'Reference Code' },
      { field: 'leadForm', header: 'Form' },
      { field: 'linkId', header: 'Link' },
    ];
  }

  getLinks() {
    this.isLoading = true;
    this.adminService.getLinks(this.companyId).subscribe(data => {
      this.tableData = data.reverse();
      this.isLoading = false;
    });
  }

  getTeams() {
    this.adminService.getTeams(this.companyId).subscribe(data => {
      this.teams = data.map((line) => {
        return { label: line.name, value: line._id };
      });
    });
  }

  getCategories() {
    this.adminService.getCategory(this.companyId).subscribe(data => {
      this.categories = data.map((line) => {
        return { label: line.name, value: line._id };
      });
    });
  }

  getPipes() {
    this.adminService.getIntegrations(this.companyId).subscribe(data => {
      console.log(data);
      this.pipes = data.integration.pipeList.map((pipe) => {
        return { label: pipe.name, value: pipe.pipeId };
      });
    });
  }

  addLink() {
    this.addModal = true;
  }

  editRow(data) {
    const teamsIds = [];
    const categoryIds = [];
    this.leadForm = data.leadForm;
    console.log(data.leadForm);
    data.teams.map((team) => {
      teamsIds.push(team._id);
    });
    data.category.map((category) => {
      categoryIds.push(category._id);
    });
    const value = {
      _id: data._id,
      title: data.title,
      message: data.message,
      teams: teamsIds,
      reference: data.reference == false ? false : true,
      leadForm: data.leadForm == false ? false : true,
      languageCode: data.languageCode,
      category: categoryIds,
      click: data.click,
      pipeId: data.pipeId
    }
    this.updateLinkForm.patchValue(value);
    this.editModal = true;
  }

  copyLink(linkId) {
    const text = BACKEND_URL + '/links/redirect/' + linkId;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.notificationService.showSuccess('Link copied!');
  }

  createLink() {
    this.formSubmitted = true;
    const data = {
      teams: this.createLinkForm.value.teams,
      category: this.createLinkForm.value.category,
      title: this.createLinkForm.value.title,
      message: this.createLinkForm.value.message,
      reference: this.createLinkForm.value.reference,
      leadForm: this.createLinkForm.value.leadForm,
      company: this.companyId,
      pipeId: this.createLinkForm.value.pipeId,
      languageCode: this.createLinkForm.value.languageCode
    };
    if (this.createLinkForm.valid) {
      this.isLoading = true;
      this.adminService.createLinks(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.addModal = false;
        this.getLinks();
        this.createLinkForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  updateLink() {
    this.formSubmitted = true;
    const data = {
      teams: this.updateLinkForm.value.teams,
      category: this.updateLinkForm.value.category,
      title: this.updateLinkForm.value.title,
      message: this.updateLinkForm.value.message,
      reference: this.updateLinkForm.value.reference,
      leadForm: this.updateLinkForm.value.leadForm,
      languageCode: this.updateLinkForm.value.languageCode,
      company: this.companyId,
      click: this.updateLinkForm.value.click,
      pipeId: this.updateLinkForm.value.pipeId,
      _id: this.updateLinkForm.value._id
    };
    if (this.updateLinkForm.valid) {
      this.isLoading = true;
      this.adminService.updateLink(this.companyId, data).subscribe(() => {
        this.formSubmitted = false;
        this.isLoading = false;
        this.editModal = false;
        this.leadForm = false;
        this.getLinks();
        this.updateLinkForm.reset();
      }, () => {
        this.formSubmitted = false;
        this.isLoading = false;
      });
    } else {
      this.formSubmitted = true;
      this.notificationService.showError('Please fill fields!');
    }
  }

  deleteRow(linkId) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the link?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.adminService.deleteLink(this.companyId, linkId).subscribe(() => {
          this.getLinks();
        });
      }
    });
  }

  exportExcel() {
    let data = [];
    this.tableData.map(value => {
      const row = [];
      row.push(value.title);
      value.category.forEach(element => {
        row.push(element.name);
      });
      row.push(value.click);
      row.push(BACKEND_URL + '/links/redirect/' + value.linkId);
      data.push(row);
    });
    console.log(data);
    data.splice(0, 0, this.cols.map(column => column.header));
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Links');

    /* save to file */
    const time = Date.now();
    const fileName = 'links-' + time + '.xlsx';
    XLSX.writeFile(wb, fileName);
  }

  changeLeadFormStatus(event) {
    this.leadForm = event;
  }

}
